<template>
  <div v-if="!isUiLocked" class="contentWrapper">
    <h2>{{ propsData.title }}</h2>
    <Flickity v-if="propsData" class="tabs2" :options="flickityOptions">
      <div
        v-for="(item, key, idx) in propsData.list"
        :key="idx"
        @click="getIndex(key)"
        :class="{ active: key == currentIndex }"
        class="item"
      >
        {{ item.title }}
      </div>
    </Flickity>
    <div v-html="propsData.list[currentIndex]?.text"></div>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
import { mapGetters } from "vuex";
export default {
  components: {
    Flickity
  },
  props: {
    propsData: {
      type: [Array, Object]
    }
  },
  data() {
    return {
      currentIdx: 1,
      flickityOptions: {
        // prevNextButtons: false,
        pageDots: false,
        contain: true,
        cellAlign: "left",
        groupCells: true
      }
    };
  },
  methods: {
    createAnkerListing() {
      if (!this.isUiLocked) {
        const h2List = document.querySelectorAll(".contentWrapper > h2"),
          ankerList = [];
        h2List.forEach((el) => {
          ankerList.push({
            title: el.innerText,
            link: `#${el.innerText}`,
            cord: el.offsetTop
          });
          el.id = el.innerText;
        });
        // console.log(ankerList);
        this.$emit("ankerListCreated", ankerList);
      }
    },
    getIndex(idx) {
      this.currentIdx = idx;
    }
  },
  computed: {
    ...mapGetters(["isUiLocked"]),
    currentIndex() {
      return parseInt(this.currentIdx);
    }
  },
  mounted () {
    this.currentIdx = Object.keys(this.propsData.list)[0]
    this.createAnkerListing();
  },
};
</script>

<style lang="sass" scoped>
// .tabs
//     display: flex
//     border: 2px solid #4AA7BC
//     box-sizing: border-box
//     width: fit-content
//     justify-content: center
//     margin-top: 35px
//     margin-bottom: 30px
//     @media (max-width: 768px)
//         width: 100%
//         overflow-x: scroll
//     li
//         font-weight: 300
//         font-size: 14px
//         line-height: 100%
//         color: #4AA7BC
//         cursor: pointer
//         white-space: nowrap
//         margin-bottom: 0px
//         padding: 12px 20px
//         &:not(:last-of-type)
//             border-right: 2px solid #4AA7BC
//         &.active
//             color: #17171D
//             font-weight: 600
//             background-color: #4AA7BC
</style>
<style lang="sass">
.tabs2
    margin-bottom: 20px
.flickity-button
    padding: 0
    background-color: #2a4651
    transform: unset
    border-radius: 0
    width: 40px
    height: unset

    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    color: $c-btn
    box-shadow: 0 0 0px 1px inset $c-btn
    svg
        height: 12px
    &:disabled
        opacity: 0

    .flickity-button-icon
        width: 15px
        left: unset
        right: unset
        top: unset
        bottom: unset
        position: static
.previous
    left: 0
    bottom: 0
    top: 0
.next
    right: 0
    bottom: 0
    top: 0

.item
    padding: 12px 20px
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    color: $c-btn
    box-shadow: 0 0 0px 1px inset $c-btn
    &.router-link-exact-active.router-link-active, &.active
        color: #17171D
        font-weight: 600
        background-color: #4AA7BC
.contentWrapper::v-deep
    background-color: red
    .table > td
        text-align: unset
</style>
