<template>
  <div class="country" v-if="!isUiLocked">
    <div class="container textContainer">
        <app-breadcrumbs :breadcrumbs="breadcrumbsList"></app-breadcrumbs>
      <the-main-text
        class="smallmb"
        :title="tourInfo.name"
        :descr="tourInfo.description"
      ></the-main-text>
      <ul class="attrWrp">
        <li
          class="attrItem"
          v-for="(item, idx) in attributeFiltered"
          :key="idx"
        >
          <i class="icon" :class="item.attr_icon"></i>
          {{ item.value }}
        </li>
      </ul>
      <ul class="btnWrp">
        <li>
            <a :href="`${$route.path}/pdf`" target="_blank">
             {{ $t('hotelText1') }}
            </a>
        </li>
        <li @click="scrollReqForm">{{ $t('tourText2') }}</li>
      </ul>
    </div>
      <app-main-carousell
        v-if="galleryData"
        :galleryData="galleryData.content"
      ></app-main-carousell>
    <div class="container infoZone">
      <anker-list
        :ankerList="ankerList"
        :title="$t('hotelAnkerListTitle')"
        @click="onAnkerClick"
      ></anker-list>
      <div class="contentZone" ref="contentZone">
        <components
          v-for="(item, idx) in constructorData_m"
          :is="item.component"
          :key="idx"
          @ankerListCreated="setAnkerList"
          :propsData="item.content"
          :currency="currency"
        ></components>
      </div>
    </div>
    <component
      v-for="(item, idx) in widgetList"
      :key="idx"
      :is="item.component"
      :propsData="item.content"
    >
    </component>
    <section v-if="alternateHotels.length" class="hotelListWrp">
      <div>
          <h2 class="sectionTitle bef bef-fix">{{ $t("alternateHotels") }}</h2>
      <div class="container hotelList">
        <app-hotel-item
          v-for="(hotel, idx) in alternateHotels"
          :key="idx"
          :propsData="hotel"
        ></app-hotel-item>
      </div>
      <router-link
        :to="{
          name: 'ContryHotels',
          params: { countryName: this.$route.params.countryName }
        }"
        class="btn"
      >
        {{ $t("seeOtherHotels") }}
        <i class="icon ic-arrow"></i>
      </router-link>
    </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import appMainCarousell from "../components/common/app-MainCarousell.vue";
import AppTextTabs from "../components/common/app-text-tabs.vue";
import SimpleText from "../components/text-components/app-simple-text.vue";
import accordionMixin from "@/textAccordion";
import AppHotelItem from "../components/common/app-hotel-item.vue";
import TheMainText from "../components/common/the-main-text.vue";
import AnkerList from "../components/navigation/anker-list.vue";
import HotelRooms from "../components/hotel/hotel-rooms.vue";
import AppBreadcrumbs from '../components/common/app-breadcrumbs.vue';
import RequestForm from '../components/common/RequestForm.vue';
import AppNews from '../components/app-news.vue';
export default {
  components: {
    appMainCarousell,
    SimpleText,
    tabs: AppTextTabs,
    AppHotelItem,
    TheMainText,
    AnkerList,
    "items-tabs": HotelRooms,
    AppBreadcrumbs,
    "form1": RequestForm,
    "news": AppNews,
  },
  mixins: [accordionMixin],
  data() {
    return {
      tourInfo: null,
      constructorData: null,
      widgetList: null,
      hotelAttribute: null,
      alternateHotels: null,
      ankerList: null,
      breadcrumbsList: null,
      currency: null,
    };
  },
  methods: {
    ...mapActions(["lockUi", "unlockUi"]),
    setAnkerList(ankerList) {
      this.ankerList = ankerList;
    },
    scrollReqForm(){
        const form = document.querySelector('#requestForm').offsetTop
        window.scrollTo(0, form - 120)
    }
  },
  computed: {
    ...mapGetters(["isUiLocked"]),
    attributeFiltered() {
      let temp = [],
        keys = [];
      this.hotelAttribute.forEach((el) => {
        let found = false;
        keys.forEach((key) => {
          if (el.attribute_id == key) found = true;
        });
        if (!found) {
          temp.push(el);
          keys.push(el.attribute_id);
        }
      });
      return temp;
    },
    galleryData() {
        let temp = null;
        this.constructorData.forEach(item => {
            if ( item.component == 'gallery' ) {
                temp = item
            }
        });
        return temp
    },
    constructorData_m() {
        let temp = [];
        this.constructorData.forEach(item => {
            if ( item.component != 'gallery' ) {
                temp.push(item)
            }
        });
        return temp
    }
  },
  async created() {
    this.lockUi();
    const res = await this.axios.post("/api/hotel/get-by-slug", {
      slug: this.$route.params.slug,
      lang: this.currentLang
    });
    this.tourInfo = res.data.data.translate;
    this.currency = res.data.data.model.currency
    this.constructorData = res.data.data.constructor;
    this.widgetList = res.data.data.widgets;
    this.hotelAttribute = res.data.data.model.hotel_attributes;
    this.alternateHotels = res.data.data.alternative_hotels;
    this.breadcrumbsList = res.data.data.breadcrumbs
    document.title = res.data.data.translate.meta_title || res.data.data.translate.name || "Dream Travel"
    setTimeout(() => {
      this.initToggleSlideBtn(this.$refs.contentZone, false); // from mixin
    }, 500);
    this.unlockUi();
  }
};
</script>

<style lang="sass" scoped>
h1, h1 + .descr
    margin-bottom: 17px
.hotelListWrp
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    // @include sectionPadding
    // @media (max-width: 830px)
    //     padding: 100px 0px
.hotelList
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-gap: 28px
    flex-wrap: wrap
    margin-top: 77px
    @media (max-width: 1024px)
        justify-content: center
        grid-template-columns: repeat(2, 1fr)
    @media (max-width: 830px)
        grid-template-columns: repeat(2, 1fr)
    @media (max-width: 576px)
        flex-direction: column
        grid-template-columns: repeat(1, 1fr)
        margin-top: 0px
.attrWrp
    margin-top: 18px
    margin-bottom: 30px
    display: flex
    justify-content: center
    @media (max-width: 576px)
        display: grid !important
        grid-template-columns: repeat(2,1fr)
        grid-gap: 15px
        text-align: center
        .attrItem
            margin-left: 0px
.attrItem
    color: #fff
    font-weight: 600
    font-size: 14px
    line-height: 100%
    margin-left: 30px
    i
        margin-left: 11px
        color: inherit
.attrWrp
    display: flex
.btnWrp
    display: flex
    justify-content: center
    @media (max-width: 576px)
        flex-direction: column
        li
            margin-left: 0px !important
            margin-top: 10px
            text-align: center !important
    li
        font-weight: 600
        cursor: pointer
        font-size: 18px
        line-height: 120%
        text-align: right
        color: $c-btn
        padding: 20px
        border: 1px solid $c-btn
        &:last-of-type
            margin-left: 25px
            color: #fff
            background-color: $c-btn
.btn i
    font-size: 12px
    margin-left: 12px
</style>
