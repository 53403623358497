<template >
  <div class="contentWrapper">
    <!-- {{ propsData }} -->
    <h2>{{ propsData.title }}</h2>
    <Flickity v-if="propsData" class="tabs3" :options="flickityOptions">
      <div
        v-for="(item, idx) in propsData.list"
        @click="currentHotelIdx = idx"
        :class="{ active: currentHotelIdx == idx }"
        :key="idx"
        class="item"
      >
        {{ item.title }}
      </div>
    </Flickity>
    <div class="hotelRoomWrapper">
      <hotel-room-item
        v-for="(item, idx) in currentRooms.items"
        :key="idx"
        :roomInfo="item"
      ></hotel-room-item>
    </div>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
import hotelRoomItem from "./hotel-rooms-item.vue";
export default {
  components: { hotelRoomItem, Flickity },
  props: {
    propsData: {
      type: [Array, Object]
    }
  },
  data() {
    return {
      currentHotelIdx: 1,
      flickityOptions: {
        // prevNextButtons: false,
        pageDots: false,
        contain: true,
        cellAlign: "left",
        groupCells: true
      }
    };
  },
  computed: {
    currentRooms() {
      const temp = this.propsData.list[this.currentHotelIdx];
      return temp;
    }
  }
};
</script>

<style lang="sass">
.tabs3
  margin-bottom: 40px
  .flickity-button
    padding: 0
    background-color: #2a4651
    transform: unset
    border-radius: 0
    width: 40px
    height: unset

    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    color: $c-btn
    box-shadow: 0 0 0px 1px inset $c-btn
    svg
      height: 12px
    &:disabled
      opacity: 0

    .flickity-button-icon
      width: 15px
      left: unset
      right: unset
      top: unset
      bottom: unset
      position: static
  .previous
    left: 0
    bottom: 0
    top: 0
  .next
    right: 0
    bottom: 0
    top: 0

  .item
    padding: 12px 20px
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    color: $c-btn
    box-shadow: 0 0 0px 1px inset $c-btn
    &.router-link-exact-active.router-link-active, &.active
      color: #17171D
      font-weight: 600
      background-color: #4AA7BC
</style>
<style lang="sass" scoped>
.hotelRoomWrapper
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 28px
    margin-bottom: 50px
    @media (max-width: 576px)
        grid-template-columns: 1fr
</style>
